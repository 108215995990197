<template>
  <div class="define-menu-detail-wrapper">
    <van-nav-bar
      :title="title"
      :border="false"
      left-arrow
      fixed
      @click-left="$router.back()"
    />
    <div class="search-total">共 {{ total }} 条记录</div>
    <van-list
      v-model="loading"
      class="config-list"
      :finished="finished"
      :finished-text="list.length == 0 ? '' : '没有更多了'"
      @load="getData"
    >
      <van-empty
        v-if="loaded && list.length === 0"
        image="search"
        description="查无数据"
      />
      <div v-for="item in list" :key="item.id" class="config-list-item">
        <div
          v-for="(c, i) in item.list"
          :key="item.id + i"
          class="config-field"
          @click="onClickToDetail2(item)"
        >
          <span class="config-field-label">{{ c.label }}: </span>
          <span class="config-field-value">{{ c.value }}</span>
        </div>
        <div class="config-field">
          <span class="config-field-label">更新人: </span>
          <span class="config-field-value">{{ item.userName }}</span>
        </div>
        <div class="config-field">
          <span class="config-field-label">更新时间: </span>
          <span class="config-field-value">{{ item.utime }}</span>
        </div>
      </div>
    </van-list>
  </div>
</template>

<script>
import { getSafetyRecordPage } from "@/api/psm";
export default {
  name: "DefineMenuDetail",
  props: {
    id: {
      type: String,
      default: ""
    },
    name: {
      type: String,
      default: ""
    },
    code: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      title: "",
      loading: false,
      query: {
        page: 1,
        size: 10,
        sort: "utime_desc"
      },
      list: [],
      finished: false,
      loaded: false,
      total: 0
    };
  },
  created() {
    // 查询数据
    // this.getData();
    this.title = this.name;
  },
  methods: {
    onClickToDetail2(item) {
      this.$router.push({
        name: "independent-menu-detail2",
        params: {
          list: item
        }
      });
    },
    getData() {
      getSafetyRecordPage({
        ...this.query,
        classifyId: this.code
      }).then(res => {
        const { list, total } = res;
        this.total = total;
        this.loaded = true;
        this.formatList(list);
        if (list.length < this.query.size) {
          this.finished = true;
        } else {
          this.query.page++;
        }
      });
    },
    formatList(res) {
      const l = [];
      res.forEach(item => {
        const jsonItemValue = JSON.parse(item.value);
        const itemL = [];
        const inputList = jsonItemValue.filter(a =>
          ["input", "date"].includes(a.type)
        );
        if (inputList.length > 0) {
          inputList.forEach(item2 => {
            itemL.push(item2);
          });
          l.push({
            id: item.id,
            userName: item.userName,
            utime: item.utime,
            list: itemL.length <= 1 ? itemL : itemL.slice(0, 1),
            originList: itemL
          });
        }
      });
      this.list = this.list.concat(l);
    }
  },
  mounted() {
    console.log("code", this.code);
  }
};
</script>

<style lang="scss" scoped>
.define-menu-detail-wrapper {
  padding-top: 60px;
  .search-total {
    text-align: center;
    font-size: 12px;
    color: #8c8f97;
    padding-top: 12px;
  }
  .config-list {
    .config-list-item {
      min-height: 70px;
      box-sizing: border-box;
      padding: 10px 16px;
      padding-bottom: 0;
      border-bottom: 8px solid #eceef2;
      font-size: 12px;
      .config-field {
        height: 20px;
        line-height: 20px;
        margin-bottom: 8px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 14px;
        &-label {
          display: inline-block;
          min-width: 68px;
          color: #8c8f97;
          margin-right: 8px;
        }
        &-value {
          color: #2e2e4d;
        }
      }
    }
  }
}
</style>
